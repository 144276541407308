#navbar {
  z-index: 10;
  transition: all .3s;
  position: fixed;
}

#navbar:before {
  transition: all .3s;
  animation: fadeIn .3s forwards;
}

.navbar {
  padding-top: 0;
}

#navbar-container {
  padding-top: 7px;
}

#navbar-items-container {
  justify-content: end;
  font-family: Segoe UI;
}

.navbar-brand {
  font-family: mvboli, MV Boli, Kalam;
  font-size: 35px;
  font-weight: bold;
  line-height: 0;
}

.nav-item {
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 19px;
  transition: all .2s;
}

.nav-item:hover {
  background-color: #fff;
}

.nav-link {
  color: #000 !important;
  padding: 11px 18px 13px !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*# sourceMappingURL=app-page.13742c39.css.map */
